.partners-section {
    padding-top: 100px;
    position: relative;
}

.partners-section__title {
    background: url('../../assets/images/partners-icon.svg') no-repeat 150px 0;
    font-size: 44px;
    line-height: 58px;
    padding: 100px 0 56px;
}

.partners-section__block {
    background-color: var(--color-blue-light);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 75px 50px;
}

.partners-section__list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 2rem;
}

@media (max-width: 950px) {
    .partners-section {
        padding-top: 40px;
    }

    .partners-section__title {
        background: none;
        display: flex;
        align-items: center;
        font-size: 32px;
        line-height: 42px;
        padding: 0 0 32px;
        margin: 0;
    }

    .partners-section__title:before {
        content: '';
        background: url('../../assets/images/partners-icon-small.svg') no-repeat 50%;
        display: block;
        border: 1px solid var(--color-primary);
        border-radius: 52px;
        height: 94px;
        min-height: 94px;
        width: 94px;
        min-width: 94px;
        margin-inline-end: 32px;
    }
}
