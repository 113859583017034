.contacts-section {
    padding: 75px var(--main-offset) 80px;
    position: relative;
    background-color: var(--background-color);
    min-width: calc(360px - calc(var(--main-offset) * 2));
}

.contacts-section__info {
    padding-top: 65px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 2rem;
}

.contacts-section__info .logo {
    margin: 0;
}

.contacts-section__info-data {
    display: flex;
    flex: 1 1 auto;
}

.contacts-section__info-data-title {
    display: flex;
    margin: 0;
    padding-top: 20px;
    padding-inline-start: 30px;
    font-size: 16px;
}

.contacts-section__info-data-title:after {
    background: var(--color-white) url('../../assets/images/long-arrow.svg') no-repeat 100% 50%;
    content: '';
    display: block;
    height: 22px;
    width: 220px;
    margin: 0 30px;
}

.contacts-section__info-data-address {
    display: flex;
    flex-direction: column;
    padding-top: 18px;
}

.contacts-section__info-data-address-text {
    font-family: var(--font-family-primary-bold);
    padding-top: 30px;
}

.contacts-section__info-social-title {
    margin: 0;
    padding: 20px 0;
    font-size: 16px;
}

.contacts-section__info-social-links {
    display: flex;
    justify-content: center;
    column-gap: 20px;
}

.contacts-section__documents {
    flex: 1 1 auto;
}

.contacts-section__info-document-links {
    margin: 0;
    padding-inline-start: 1rem;
}

.contacts-section__info-document-links li {
    padding-bottom: .5rem;
}

.contacts-section__info-document-links a {
    color: var(--text-color);
    text-decoration: none;
}

.contacts-section__info-document-links a:hover {
    color: var(--color-primary);
}

@media (max-width: 1280px) {
    .contacts-section__info-data {
        flex-direction: column;
    }

    .contacts-section__info-data-title {
        padding-inline-start: 0;
    }

    .contacts-section__info-data-title:after {
        display: none;
    }
}

@media (max-width: 950px) {
    .contacts-section {
        padding: 65px var(--main-offset) 80px;
    }

    .contacts-section__info,
    .contacts-section__info-data {
        flex-direction: column;
    }

    .contacts-section__info-data-title {
        padding-inline-start: 0;
    }

    .contacts-section__info .logo,
    .contacts-section__info-data-title:after {
        display: none;
    }

    .contacts-section__info-social-links {
        justify-content: flex-start;
    }
}
