div[class*='-fpv-drones-donation-page'] {
    background-color: var(--color-light-grey);
}

.project-fpv-page__title {
    padding-top: 25px;
}

.project-fpv-page__partners {
    display: flex;
    justify-content: center;
    padding-top: 40px;
}
