.fundraising-item {
    border: 1px solid var(--color-black);
    margin-inline-end: 45px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 2px);
    width: var(--fundraising-item-width);
}

.fundraising-item__image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    border-bottom: 1px solid var(--color-black);
    height: 223px;
    min-height: 223px;
    width: 100%;
}

.fundraising-item__content {
    display: flex;
    flex-direction: column;
    justify-items: stretch;
    padding: 40px 24px 20px;
    height: 100%;
    max-height: 370px;
}

.fundraising-item__bottom {
    margin-top: auto;
}

.fundraising-item__content-title {
    margin: 0;
    font-size: 22px;
    line-height: 30px;
    padding-bottom: 16px;
    hyphens: auto;
}

.fundraising-item__content-description {
    margin: 0;
    overflow: auto;
    padding-bottom: 16px;
    font-size: 14px;
}

.fundraising-item__need-amount {
    padding-top: 16px;
    font-size: 24px;
    line-height: 2rem;
    display: flex;
}

.fundraising-item__currency {
    font-size: 16px;
}

.fundraising-item__progress {
    height: 14px;
    margin-top: 12px;
    overflow: hidden;
}

.fundraising-item__progress_ongoing {
    margin-top: 32px;
    border: 1px solid var(--border-color);
}

.fundraising-item__progress-bar {
    height: 100%;
    background-color: var(--color-primary);
}

.fundraising-item__progress_ongoing,
.fundraising-item__progress_ongoing .fundraising-item__progress-bar {
    border-radius: 8px;
}

.fundraising-item__collected-amount {
    padding-top: 5px;
}

.fundraising-item__button {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 13px;
    margin-top: 16px;
    padding: 0;
    text-decoration: none;
    color: var(--color-white);
}

.fundraising-item__button:before {
    content: '+';
    color: var(--color-white);
    display: flex;
    align-items: center;
    font-size: 30px;
    justify-content: center;
    background-color: var(--color-primary);
    height: 44px;
    width: 44px;
    border-radius: 22px;
    margin-inline-end: 15px;
    transition: all .2s ease-in-out;
}

.fundraising-item__button:hover:before {
    background-color: var(--color-primary-hover);
}

.fundraising-item__button_complete {
    margin-top: 24px;
    display: flex;
    justify-content: center;
}

@media (max-width: 950px) {
    .fundraising-item {
        margin-inline-end: 12px;
    }

    .fundraising-item__image {
        height: 170px;
        min-height: 170px;
    }

    .fundraising-item__content {
        padding: 20px 24px 20px;
    }

    .fundraising-item__progress_ongoing {
        margin-top: 20px;
    }

    .fundraising-item__content-description {
        padding-bottom: 6px;
    }
}
