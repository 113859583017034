.about-section {
    display: flex;
    padding-top: 24px;
    position: relative;
}

.about-section__main-title {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--color-white);
    height: 360px;
    padding-inline-start: 60px;
    display: flex;
    align-items: center;
    font-size: 44px;
    margin: 0;
}

.about-section__main-title span {
    max-width: 500px;
    padding: 60px 0;
}

.about-section__block {
    width: 50%;
    border: 1px solid var(--border-color);
    padding: 65px 100px 65px 60px;
}

.about-section__block-title {
    margin: 0 0 50px;
    font-size: 44px;
}

.about-section__block-text {
    line-height: 21px;
    margin: 0;
}

.about-section__block-mission {
    background: url('../../assets/images/trident.svg') no-repeat calc(100% - 100px) 50%;
    border-right: none;
}

.about-section__block-mission .about-section__block-text {
    padding-inline-end: 130px;
}

.about-section__block-fund .about-section__block-text {
    font-size: 24px;
    line-height: 32px;
}

.about-section__details-link {
    display: flex;
    align-items: center;
    color: var(--color-black);
    font-size: 14px;
    text-decoration: none;
    width: fit-content;
    margin-top: 50px;
    height: 45px;
}

.about-section__details-link:hover {
    text-decoration: underline;
}

.about-section__details-link:before {
    content: '';
    background: url('../../assets/images/arrow.svg') no-repeat 50%;
    background-size: 30%;
    display: block;
    height: 45px;
    width: 45px;
    border: 1px solid var(--color-black);
    border-radius: 30px;
    margin-inline-end: 15px;
    transform: rotate(180deg);
}

@media (max-width: 950px) {
    .about-section {
        flex-direction: column;
    }

    .about-section__main-title {
        margin: 0 calc(var(--main-offset) * -1);
        font-size: 24px;
        padding-inline-start: 45px;
        align-items: flex-end;
    }

    .about-section__main-title span {
        max-width: 285px;
        padding: 24px 0;
    }

    .about-section__block {
        width: auto;
        padding: 30px 25px 45px 28px;
    }

    .about-section__block-title {
        margin: 0 0 32px;
        font-size: 32px;
    }

    .about-section__block-mission {
        background: url('../../assets/images/trident.svg') no-repeat 30px 25px;
        background-size: 40px 67px;
        border-right: 1px solid var(--border-color);
        border-bottom: none;
        padding-top: 120px;
    }

    .about-section__block-mission .about-section__block-text {
        background: none;
        padding-inline-end: 0;
    }

    .about-section__block-fund .about-section__block-text {
        font-size: 21px;
        line-height: 28px;
    }
}