.drones-stats {
    padding-top: 120px;
}

.drones-stats__title {
    display: flex;
    justify-content: stretch;
    font-size: 4.875rem;
    font-weight: 400;
    column-gap: 30px;
    margin: 0 0 78px 0;
}

.drones-stats__title-date {
    font-size: .875rem;
    flex: 1;
    text-align: end;
    white-space: nowrap;
}

.drones-stats__container {
    border: 1px solid black;
    display: flex;
}

.drones-stats__container-title {
    margin: 0 0 25px;
    font-size: 2.25rem;
    font-weight: 400;
    line-height: 1;
}

.drones-stats__need-amount-wrapper {
    font-weight: 400;
    flex: 1;
    border-inline-end: 1px solid var(--color-black);
}

.drones-stats__need-amount {
    padding: 50px;
}

.drones-stats__need-amount-value-wrap {
    display: flex;
    align-items: center;
    padding-block: 20px;
}

.drones-stats__need-amount-value {
    font-weight: 900;
    font-size: 5.5rem;
}

.drones-stats__currency {
    font-size: 16px;
    height: fit-content;
    padding-block: 5px !important;
    margin-inline-end: 36px !important;
}

.drones-stats__progress-container {
    display: flex;
    column-gap: 22px;
    align-items: center;
    margin-top: 36px;
}

.drones-stats__progress {
    margin: 0;
    height: 56px;
    border-radius: 56px;
    flex: 1;
}

.drones-stats__progress .drones-stats__progress-bar {
    border-radius: 56px;
    color: var(--color-white);
    display: flex;
    justify-content: flex-end;
    align-items: center
}

.drones-stats__progress-bar-percentage {
    margin-inline-end: 25px;
    font-weight: 400;
}

.drones-stats__progress-bar-percentage_small-val {
    margin-inline-end: -65px;
    color: var(--color-black);
}

.drones-stats__progress-button {
    color: var(--color-black);
    font-size: 14px;
    margin-top: 0;
}

.drones-stats__progress-button:before {
    height: 56px;
    width: 56px;
    border-radius: 56px;
}

.drones-stats__donations-count {
    font-size: .875rem;
    margin-top: 36px;
}

.drones-counter__content-values-wrapper {
    flex: 1;
}

.drones-counter__content-values {
    padding-inline: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

/*.drones-counter__content-values:first-child {
    border-block-end: 1px solid var(--color-black);
}*/

.drones-counter__content-values-title {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.75rem;
    /*width: min-content;*/
}

.drones-counter__content-values-number {
    font-size: 4.875rem;
    font-weight: 400;
    line-height: 1;
}

.drones-stats__table-block {
    padding-block-start: 80px;
}

.drones-stats__table-title {
    font-size: 2rem;
    font-weight: 400;
    margin: 25px 0 45px;
}

.drones-stats__table {
    width: 100%;
    border-collapse: collapse;
    word-break: break-word;
}

.drones-stats__table th {
    text-align: start;
    color: var(--color-grey);
    border-block-end: 1px solid var(--color-dark-grey2);
    padding: 8px;
    font-weight: 400;
    word-break: auto-phrase;
}

.drones-stats__table td {
    padding: 8px;
    border-block-end: 1px solid var(--border-color2);
}

.drones-stats__table-footer td {
    background-color: #f8f8f8;

}

.drones-stats__table-footer tr:first-child td {
    border-block-end: none;
    padding-block-start: 25px;
}

.drones-stats__table-footer tr:last-child td {
    padding-block-end: 25px;
}

.drones-stats__row_reserved {
    color: #a3a3a3;
}

.drones-stats__table-img {
    width: 45px;
}

.drones-stats__table-img img {
    max-width: 45px;
}

.drones-stats__table-merged-title {
    color: var(--color-grey);
}

.drones-stats__table-merged-title,
.drones-stats__table-date-header_merged,
.drones-stats__table-date_merged {
    display: none;
}


@media (max-width: 950px) {
    .drones-stats {
        padding-top: 56px;
    }

    .drones-stats__title {
        font-size: 2rem;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 32px;
    }

    .drones-stats__title-date {
        text-align: start;
        margin-top: 24px;
    }

    .drones-stats__container {
        flex-direction: column;
    }

    .drones-stats__container:before {
        height: 57px;
        width: 55px;
        background-size: 55px;
    }

    .drones-stats__container-content {
        align-self: stretch;
        text-align: center;
    }

    .drones-stats__container-title {
        font-size: 2rem;
        margin: 22px 0 18px;
    }

    .drones-stats__progress-button {
        flex-direction: column;
    }

    .drones-stats__progress-button:before {
        margin: 10px 0 16px 0;
    }

    .drones-stats__need-amount {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px;
    }

    .drones-stats__need-amount .drones-counter__content-values-title {
        font-size: 2rem;
        line-height: 2.625rem;
        width: max-content;
    }

    .drones-stats__need-amount-value-wrap {
        justify-content: center;
        padding-bottom: 10px;
    }

    .drones-stats__need-amount-wrapper {
        border: none;
        border-block-end: 1px solid var(--color-black);
    }

    .drones-stats__need-amount-value {
        font-size: 2rem;
        padding-block: 10px;
    }

    .drones-stats__currency {
        margin-inline-end: 0 !important;
    }

    .drones-stats__need-amount-total {
        font-size: 1rem;
    }

    .drones-stats__progress-container {
        border-top: 1px solid var(--color-black);
        margin: 25px -24px 0;
        padding: 25px 24px 0;
    }

    .drones-stats__progress {
        height: 45px;
    }

    .drones-stats__donations-count {
        margin-top: 24px;
    }

    .drones-counter__content-values {
        padding: 24px;
    }

    .drones-counter__content-values-title {
        width: min-content;
        white-space: normal;
    }

    .drones-counter__content-values-number {
        font-size: 4rem;
        text-align: center;
    }

    .drones-counter__button {
        column-gap: 10px;
        margin: 35px auto 0;
    }

    .drones-stats__table-block {
        padding-block-start: 30px;
    }

    .drones-stats__table td {
        vertical-align: top;
    }

    .drones-stats__table-merged-item {
        margin-block-end: 10px;
    }

    .drones-stats__table-merged-title,
    .drones-stats__table-date-header_merged,
    .drones-stats__table-date_merged {
        display: initial;
    }

    .drones-stats__table-date-header,
    .drones-stats__table-footer-cell,
    .drones-stats__table-date {
        display: none;
    }
}

@media (max-width: 600px) {
    .drones-stats__table-img-header,
    .drones-stats__table-img,
    .drones-stats__table-img-footer {
        display: none;
    }

    .drones-stats__table {
        font-size: .875rem;
    }

    .drones-stats__table th,
    .drones-stats__table td {
        padding: 6px;
    }
}


