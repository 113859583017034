.team-section {
    padding-top: 65px;
}

.team-section__title {
    font-size: 128px;
    line-height: 128px;
    margin: 0 0 40px 0;
}

.team-section__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 24px;
}

.team-section__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.team-section__item-title {
    font-family: var(--font-family-primary-bold);
}

.team-section__item-image {
    background-repeat: repeat;
    background-position: 50%;
    background-size: cover;
    filter: grayscale(100%);
    width: 260px;
    height: 260px;
    border-radius: 130px;
}

.team-section__item-social-links {
    display: flex;
    column-gap: 20px;
    padding-top: 18px;
}

@media (max-width: 950px) {
    .team-section {
        padding-top: 40px;
    }

    .team-section__title {
        display: flex;
        align-items: center;
        font-size: 32px;
        line-height: 42px;
        margin: 0 0 32px 0;
    }

    .team-section__title:before {
        content: '';
        background: url('../../assets/images/team-icon.svg') no-repeat 50%;
        display: block;
        border: 1px solid var(--color-primary);
        border-radius: 52px;
        height: 94px;
        min-height: 94px;
        width: 94px;
        min-width: 94px;
        margin-inline-end: 32px;
    }

    .team-section__list {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 700px) {
    .team-section__list {
        grid-template-columns: 1fr;
    }
}
