.drones-banner {
    display: none;
    background-color: var(--color-dark-grey);
    background-repeat: no-repeat;
    background-size: 280px;
    background-position: -70px 20%;
    color: var(--color-white);
    position: relative;
    min-width: 360px;
}

.drones-banner_visible {
    display: block;
}

.drones-banner__close-btn {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--color-white);
    width: 12px;
    height: 12px;
    padding: 0;
    position: absolute;
    left: 12px;
    top: 12px;
    z-index: 1;
    font-size: 0;
}

.drones-banner__close-btn:before {
    content: '';
    border-top: 1px solid var(--color-white);
    display: block;
    transform: rotate(45deg);
}

.drones-banner__close-btn:after {
    content: '';
    border-top: 1px solid var(--color-white);
    display: block;
    transform: rotate(-45deg);
    margin-top: -1px;
}

.drones-banner__content {
    backdrop-filter: blur(40px);
    display: flex;
    align-items: center;
    padding: 10px var(--main-offset);
    column-gap: 35px;
    justify-content: space-between;
    cursor: pointer;
}

.drones-banner__content-right {
    display: flex;
    column-gap: 35px;
    align-items: center;
}

.drones-banner__content-left {
    display: flex;
    column-gap: 35px;
    align-items: center;
    flex: 1;
}

.drones-banner__img {
    max-height: 73px;
}

.drones-banner__title {
    margin: 10px 0;
}

.drones-banner__partners {
    flex: 1;
}

.drones-banner__partners .partners__list {
    column-gap: 1rem;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

.drones-banner__partners .partners__item {
    width: fit-content;
}

.drones-banner__partners .partners__item-logo {
    width: 100%;
}

.drones-banner__progress-container {
    display: flex;
    align-items: center;
    background-color: var(--color-white);
    border-radius: 20px;
    overflow: hidden;
}

.fundraising-item__progress.drones-banner__progress {
    margin: 0;
    height: 20px;
    border-radius: 20px;
    flex: 1;
}

.fundraising-item__progress.drones-banner__progress .drones-banner__progress-bar {
    border-radius: 20px;
    color: var(--color-white);
    display: flex;
    justify-content: flex-end;
    align-items: center
}

.drones-banner__progress-bar-percentage {
    margin-inline-end: 15px;
    font-size: .875rem;
    font-weight: 400;
}

.drones-banner__progress-bar-percentage_small-val {
    margin-inline-end: -45px;
    color: var(--color-black);
}

.drones-banner__progress-wrapper {
    display: flex;
    flex: 1;
    column-gap: .5rem;
}

.drones-banner__stat-block {
    padding-top: 6px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    flex: 1;
    row-gap: .5rem;
}

.drones-banner__stat-block-title {
    font-size: .75rem;
    line-height: 1rem;
    width: min-content;
}

.drones-banner__stat-block-value-wrap {
    display: flex;
}

.drones-banner__stat-block-value {
    font-weight: 900;
    font-size: 1.5rem;
    white-space: nowrap;
}

.drones-banner__currency {
    font-size: .8125rem;
    margin-inline-end: 15px !important;
    padding: 2px 5px !important;
}

.drones-banner__currency_total {
    font-size: .8125rem;
    margin-inline-start: 5px;
}

.drones-banner__button {
    color: var(--color-white);
    background: linear-gradient(270deg, var(--color-yellow-hot) -4.17%, var(--color-red) 100%);
    text-decoration: none;
    padding: 16px 32px;
    border-radius: 50px;
}

.drones-banner__button:hover {
    background: linear-gradient(270deg, var(--color-orange) -4.17%, var(--color-red-hot) 100%);
}

@media (max-width: 1200px) {
    .drones-banner__partners .partners__list {
        column-gap: .5rem;
    }
}

@media (max-width: 950px) {
    .drones-banner__partners .partners__list {
        display: none;
    }

    .drones-banner__content {
        column-gap: 20px;
    }
}

@media (max-width: 800px) {
    .drones-banner {
        background-size: 180px;
        background-position: -10px 10px;
    }

    .drones-banner__title {
        font-size: 1.1875rem;
    }

    .drones-banner__stat-block {
        line-height: 1.5rem;
        flex-direction: row;
        align-items: center;
    }

    .drones-banner__progress-wrapper {
        flex-direction: column;
    }

    .drones-banner__stat-block_delivered {
        display: none;
    }

    .drones-banner__currency {
        display: none;
    }

    .drones-banner__stat-block-title {
        font-size: 1rem;
        flex: 1;
    }

    .drones-banner__content {
        flex-direction: column;
        padding-bottom: 16px;
        align-items: flex-start;
    }

    .drones-banner__content-right {
        padding-bottom: 6px;
        column-gap: 5px;
    }

    .drones-banner__content-left {
        flex-direction: column;
        align-items: stretch;
        width: 100%;
    }

    .drones-banner__img {
        max-width: 120px;
    }

    .drones-banner__button {
        padding: 10px;
        text-align: center;
        margin-top: 16px;
    }
}
