.projects-section {
    padding-top: 65px;
    position: relative;
}

.projects-section__top-block {
    display: flex;
    background-image: url('../../assets/images/question-back.svg'), url('../../assets/images/question.svg');
    background-repeat: no-repeat;
    background-position: 300px 50%, 430px 50%;
    padding: 125px 0;
    margin-bottom: 50px;
}

.projects-section__title {
    font-size: 44px;
    line-height: 58px;
    margin: 0;
    width: 50%;
    padding-inline-end: 25px;
}

.projects-section__title_mobile {
    display: none;
}

.projects-section__text {
    width: 50%;
    margin: 0;
    padding-inline-start: 25px;
}

@media (max-width: 950px) {
    .projects-section {
        padding-top: 50px;
    }

    .projects-section .slick-list {
        padding-inline-start: 0 !important;
    }

    .projects-section__top-block {
        flex-direction: column;
        background: none;
        padding: 0;
    }

    .projects-section__title {
        background-image: url('../../assets/images/question-back.svg'), url('../../assets/images/question.svg');
        background-position: 0 50%, 32px 50%;
        background-size: 100px, 35px;
        background-repeat: no-repeat;
        font-size: 32px;
        line-height: 42px;
        min-height: 100px;
        margin-bottom: 40px;
        width: auto;
        padding-inline-start: 130px;
        padding-inline-end: 0;
    }

    .projects-section__title_desktop {
        display: none;
    }

    .projects-section__title_mobile {
        display: flex;
        align-items: center;
    }

    .projects-section__text {
        width: auto;
        padding-inline-start: 0;
    }
}
