.about-page__mission-title {
    padding: 100px 0 40px;
    margin: 0;
    font-size: 44px;
    line-height: 58px;
}

.about-page__mission-text {
    display: flex;
    font-size: 24px;
    line-height: 32px;
    column-gap: 100px;
}

.about-page__mission-text-col {
    flex: 1;
}

@media (max-width: 950px) {
    .about-page__mission-title {
        font-size: 32px;
        line-height: 42px;
        padding: 55px 0 45px;
    }

    .about-page__mission-text {
        flex-direction: column;
        column-gap: 0;
    }

    .about-page__mission-text-col {
        width: 100%;
    }
}
