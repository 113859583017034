.header {
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    padding: var(--main-offset);
    position: sticky;
    top: 0;
    z-index: 100;
    height: calc(var(--header-height) / 2);
}

.logo {
    display: block;
    background-image: url("../../assets/images/logo.svg");
    background-size: cover;
    text-indent: -99999px;
    margin-inline-end: 26px;
    height: 64px;
    width: 88px;
    min-width: 88px;
}

@media (max-width: 950px) {
    .logo {
        height: 51px;
        width: 69px;
        min-width: 69px;
        margin-inline-end: 26px;
    }

    .header {
        padding-top: 10px;
        padding-bottom: 12px;
    }
}
