.help-btn {
    display: block;
    background-color: var(--color-primary);
    color: var(--color-white);
    cursor: pointer;
    padding: 22px 0;
    border-radius: 35px;
    min-width: 170px;
    transition: all .2s ease-in-out;
    text-decoration: none;
    text-align: center;
}

.help-btn:hover {
    background-color: var(--color-primary-hover);
}

.help-btn:active {
    background-color: var(--color-primary-active);
    color: var(--color-black);
}

.help-btn_big {
    border-radius: 100px;
    font-size: 2rem;
    line-height: 2.625rem;
    width: 100%;
    padding: 50px 0;
}

@media (max-width: 950px) {
    .help-btn_big {
        font-size: 1.5rem;
        line-height: 2rem;
    }
}
