.loader {
    background-image: url("../../assets/images/tail-spin.svg");
    background-color: var(--color-white);
    background-repeat: no-repeat;
    background-position: 50% 25%;
}

.loader_section {
    height: 200px;
    width: 100%;
    background-position: 50%;
}

.loader_page {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 200;
}
