.drones-page__main-img {
    text-align: center;
}

.drones-page__main-img img {
    max-height: 626px;
    max-width: 626px;
    width: 100%;
    height: 100%;
}

.drones-page__description-block {
    background-color: var(--color-blue-light-transparent);
    padding: 40px 45px 40px;
    font-weight: 400;
}

.drones-page__description-block-content {
    display: flex;
    align-items: center;
    column-gap: 2rem;
}

.drones-page__description-title {
    font-size: 2.5rem;
    margin: 0 0 20px 0;
    font-weight: 400;
}

.drones-page__description-content {
    flex: 1;
    margin: 0;
    font-size: 1.5rem;
    line-height: 2rem;
}

.drones-page__description-img-wrapper {
    flex: 1;
}

.drones-page__description-img {
    padding: 30px 0 15px;
    background-position: 80px -10px;
    background-repeat: no-repeat;
}

.drones-page__description-img img {
    max-width: 300px;
    flex: 1;
}

.drones-page__description-columns {
    padding-block-start: 35px;
    display: flex;
    column-gap: 2rem;
    font-size: 1rem;
    line-height: 1.25rem;
}

.drones-page__help-btn {
    padding-top: 50px;
}

.drones-page__requisites {
    padding-top: 48px;
    text-align: center;
    font-size: 1.5rem;
}

.drones-page__requisites-content {
    text-align: start;
    padding-top: 20px;
}

.drones-page__requisites-link {
    padding-top: 60px;
}

.drones-page__partners {
    padding: 80px 0 0;
    margin-top: 45px;
}

.drones-page__partners-title {
    font-size: 2rem;
    font-weight: 400;
    margin: 0 0 60px 0;
}

@media (max-width: 1100px) {
    .drones-page__description-block {
        column-gap: 50px;
    }
}

@media (max-width: 950px) {

    .drones-page__description-img-wrapper {
        display: none;
    }

    .drones-page__description-img {
        padding: 0;
    }

    .drones-page__requisites-link {
        padding-top: 30px;
    }

    .drones-page__main-img {
        margin-inline: calc(var(--main-offset) * -1);
    }

    .drones-page__description-block {
        flex-direction: column;
        background-color: transparent;
        padding: 40px 0 35px;
    }

    .drones-page__description-title {
        font-size: 2rem;
        margin: 0 0 30px 0;
    }

    .drones-page__description-content {
        font-size: 1.25rem;
        column-gap: 3rem;
        line-height: 1.75rem;
        flex-direction: column;
        word-break: break-word;
    }

    .drones-page__description-img {
        align-self: center;
        margin-top: 40px;
        background-size: 126px;
        background-position: 50px -10px;
        background-repeat: no-repeat;
    }

    .drones-page__description-img img {
        max-width: 170px;
        margin-bottom: 10px;
    }

    .drones-page__description-columns {
        flex-direction: column;
        padding-block-start: 0;
    }

    .drones-page__help-btn {
        padding-top: 25px;
    }

    .drones-page__requisites {
        padding-top: 32px;
    }

    .drones-page__partners {
        margin-inline: calc(var(--main-offset) * -1);
        background-color: var(--color-blue-light-transparent);
        margin-top: 0;
        padding: 50px 30px 30px;
    }

    .drones-page__partners-title {
        margin: 0 0 30px 0;
    }
}

@media (max-width: 560px) {
    .drones-page__subtitle-block {
        background-position: 100px 0;
        background-size: 500px;
    }
}
