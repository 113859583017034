.partners__list {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    align-items: center;
    justify-content: center;
}

.partners__item {
    display: block;
    text-align: center;
    width: 180px;
}

.partners__item-logo {
    max-height: 120px;
    max-width: 180px;
}

.partners__list_thumbnail-size .partners__item {
    width: 40px;
}

.partners__list_thumbnail-size .partners__item-logo {
    max-width: 40px;
    max-height: 30px;
}

.partners__list_medium-size .partners__item {
    width: 120px;
}

.partners__list_medium-size .partners__item-logo {
    max-width: 120px;
    max-height: 100px;
}

@media (max-width: 950px) {
    .partners__list {
        gap: 2rem;
    }
}
