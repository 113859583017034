.reports-list {
    padding-top: 90px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

@media (max-width: 950px) {
    .reports-list {
        padding-top: 45px;
    }
}