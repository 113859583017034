.fundraising-section {
    padding-top: 45px;
    position: relative;
}

.fundraising-section__title {
    display: flex;
    align-items: center;
    font-family: var(--font-family-primary-light);
    font-size: 108px;
    line-height: 100px;
    margin: 0;
    padding-bottom: 55px;
}

.fundraising-section__title-icon {
    height: 286px;
    min-width: 286px;
    border-radius: 143px;
    background-image: url('../../assets/images/shield.svg'), url('../../assets/images/emblem.svg');
    background-color: var(--color-blue-grey);
    background-repeat: no-repeat;
    background-position: 50% 50%, 50% 48%;
    margin-inline-end: 110px;
}


@media (max-width: 950px) {
    .fundraising-section {
        padding-top: 40px;
    }

    .fundraising-section__title {
        font-size: 32px;
        line-height: 32px;
        padding-bottom: 32px;
    }

    .fundraising-section__title-icon {
        height: 94px;
        min-width: 94px;
        background-size: 40%, 17%;
        margin-inline-end: 30px;
    }
}
