.navigation {
    display: flex;
    align-self: stretch;
    flex: 1 1 auto;
    column-gap: 20px;
}

.navigation__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    font-size: 14px;
    padding: 0;
    margin: 0;
}

.navigation__item {
    display: flex;
    margin-inline-start: 20px;
}

.navigation__link {
    display: flex;
    align-items: center;
    color: var(--text-color);
    text-decoration: none;
    padding: 0 4px;
    position: relative;
    white-space: nowrap;
    transition: all .2s ease-in-out;
}

.navigation__link_active {
    color: var(--color-primary);
    font-family: var(--font-family-primary-bold);
    text-decoration: solid underline var(--color-primary) 2px;
    text-underline-offset: 10px;
}

.navigation__link:hover {
    color: var(--color-primary);
}

.navigation__link:active {
    font-family: var(--font-family-primary-bold);
}

.navigation__controls {
    display: flex;
    margin-left: auto;
}

.navigation__kebab {
    display: none;
    background: none;
    border: none;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-inline-start: 42px;
    cursor: pointer;
}

.navigation__kebab:before {
    content: '';
    display: block;
    border-top: 2px solid var(--color-black);
    margin-block-end: 7px;
    width: 30px;
    transition: all .2s ease-in-out;
}

.navigation__kebab:after {
    content: '';
    display: block;
    border-top: 2px solid var(--color-black);
    width: 15px;
    transition: all .2s ease-in-out;
}

.navigation_visible .navigation__kebab:before {
    margin-block-end: -2px;
    transform: rotate(-45deg);
}

.navigation_visible .navigation__kebab:after {
    transform: rotate(45deg);
    width: 30px;
}

.navigation__item.navigation__translation_mobile {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    margin-inline-end: 8px;
}

@media (max-width: 950px) {
    .navigation__translation {
        display: none;
    }

    .navigation__item.navigation__translation_mobile {
        display: block;
    }

    .navigation__kebab {
        display: flex;
        margin-inline-start: 15px;
    }

    .navigation__list {
        position: absolute;
        left: 0;
        top: 85px;
        display: block;
        width: 100%;
        background: #fff;
        max-height: 0;
        overflow: hidden;
        transition: all .2s ease-in-out;
    }

    .navigation__item {
        margin-inline-end: var(--main-offset);
    }

    .navigation_visible .navigation__list {
        max-height: 100vh;
        padding-bottom: var(--main-offset);
    }

    .navigation__link {
        padding: 20px 0;
        width: 100%;
    }
}
