.report-item {
    display: block;
    color: var(--color-black);
    font-size: 16px;
    padding: 15px 0;
    text-decoration: none;
    transition: all .2s ease-in-out;
}

.report-item:not(:last-child) {
    border-bottom: 1px solid var(--border-color2);
}

.report-item:hover {
    color: var(--color-primary-hover);
}
