html, body {
  margin: 0;
  font-family: var(--font-family-primary-light);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: var(--text-color);

  scroll-behavior: smooth;

  min-width: 200px;
}
