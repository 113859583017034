a {
    color: var(--color-primary);
}

.section__anchor {
    position: absolute;
    top: calc(var(--header-height) * -1);
}

.text-highlight {
    border-radius: 20px;
    padding: 0 15px;
    margin: 0 5px;
}

.text-highlight_yellow {
    background-color: var(--color-yellow);
}

.text-highlight_blue {
    background-color: var(--color-blue);
}

.text-highlight_black {
    background-color: var(--color-black);
    color: var(--color-white);
}

.text-highlight_white {
    background-color: var(--color-white);
    color: var(--color-black);
}

.complete__button {
    display: flex;
    align-items: center;
    background: var(--color-white) url('../../assets/images/check-icon.svg') no-repeat 50%;
    border: 1px solid var(--color-primary);
    border-radius: 52px;
    height: 94px;
    min-height: 94px;
    width: 94px;
    min-width: 94px;
}

.page__main-title {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--color-white);
    height: 360px;
    padding-inline-start: 60px;
    display: flex;
    align-items: center;
    font-size: 44px;
    margin: 0;
}

.page__main-title-text {
    max-width: 500px;
    padding: 60px 0;
}

.page__description {
    display: flex;
    padding-top: 80px;
    font-size: 24px;
    line-height: 32px;
}

.page__description-text {
    padding-top: 30px;
}

.page__description:before {
    content: '';
    display: block;
    height: 120px;
    min-width: 120px;
    width: 120px;
    border-radius: 70px;
    background-image: url('../../assets/images/shield.svg'), url('../../assets/images/emblem.svg');
    background-color: var(--color-blue-grey);
    background-repeat: no-repeat;
    background-position: 50% 50%, 50% 48%;
    background-size: 40%, 17%;
    margin-inline-end: 30px;
}

@media (min-width: 950px) {
    .complete__button_big {
        height: 194px;
        min-height: 194px;
        width: 194px;
        min-width: 194px;
        border-radius: 97px;
        background: var(--color-white) url('../../assets/images/check-icon-big.svg') no-repeat 50%;
    }
}

@media (max-width: 950px) {
    .page__main-title {
        margin: 0 calc(var(--main-offset) * -1);
        font-size: 24px;
        padding-inline-start: 45px;
        align-items: flex-end;
    }

    .page__main-title-text {
        max-width: 285px;
        padding: 24px 0;
    }

    .page__description {
        flex-direction: column;
        padding-top: 25px;
        font-size: 16px;
        line-height: 21px;
    }

    .page__description-text {
        padding-top: 25px;
    }

    .complete__button {
        border-radius: 52px;
        height: 70px;
        min-height: 70px;
        width: 70px;
        min-width: 70px;
    }
}
