:root {
    --color-primary: #0359FF;
    --color-primary-hover: #0386FF;
    --color-primary-active: #FFF500;
    --color-primary-disabled: #DBE0E9;
    --color-white: #ffffff;
    --color-black: #000000;
    --color-dark-grey: #01080B;
    --color-dark-grey2: #4A545A;
    --color-grey: #797c7e;
    --color-light-grey: #eff1f3;
    --color-yellow: #faf687;
    --color-yellow-hot: #FFA800;
    --color-orange: #d58d00;
    --color-red: #FF2E00;
    --color-red-hot: #d32500;
    --color-blue: #d4fcff;
    --color-blue-grey: #F0F3FF;
    --color-blue-light: #F3F9FC;
    --color-blue-light-transparent: #F3F9FC60;
    --color-success: #439f35;
    --color-error: #cb4545;

    --background-color: var(--color-white);

    --text-color: #000000;

    --border-color: #B9B9B9;
    --border-color2: #D0DDE4;

    --main-offset: 32px;

    --header-height: 128px;

    --simple-btn-hover-background-color: #ebebeb;

    --fundraising-item-width: 334px;

    --slider-btn-height: 70px;

    --font-family-primary-light: 'e-Ukraine-Light', '-apple-system', 'BlinkMacSystemFont', Arial, sans-serif;
    --font-family-primary-regular: 'e-Ukraine-Regular', '-apple-system', 'BlinkMacSystemFont', Arial, sans-serif;
    --font-family-primary-bold: 'e-Ukraine-Bold', '-apple-system', 'BlinkMacSystemFont', Arial, sans-serif;
}

@media (max-width: 950px) {
    :root {
        --main-offset: 24px;
        --fundraising-item-width: 300px;
    }
}
