.slick-slider {
    margin: 0 calc(var(--main-offset) * -1);
    padding-bottom: calc(var(--slider-btn-height) + 40px);
}

.slick-slider_no-arrows {
    padding-bottom: 50px;
}

.slick-list {
    padding-inline-start: var(--main-offset) !important;
}

.slick-initialized .slick-slide {
    display: flex !important;
    height: auto;
}

.slick-track {
    display: flex !important;
}

.slider__button {
    background: var(--color-white) url('../../assets/images/arrow.svg') no-repeat 22px 50%;
    border: 1px solid var(--color-black);
    cursor: pointer;
    height: var(--slider-btn-height);
    padding: 0 22px;
    position: absolute;
    right: calc(var(--main-offset) + (var(--fundraising-item-width) / 2));
    bottom: 0;
    width: calc(var(--fundraising-item-width) / 2 + 1px);
    transition: all .2s ease-in-out;
}

.slider__button[disabled] {
    pointer-events: none;
    opacity: .5;
    cursor: default;
}

.slider__button-next {
    transform: rotateY(180deg);
    right: var(--main-offset);
}

.slider__button:hover {
    box-shadow: inset 0 0 0 1px var(--color-black);
}

.slider__button:active {
    background-image: url('../../assets/images/arrow-white.svg');
    background-color: var(--color-black);
    color: var(--color-white);
}

@media (max-width: 950px) {
    .slick-slider_no-arrows {
        padding-bottom: 0;
    }
}
