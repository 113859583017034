.toasts-wrapper {
    position: fixed;
    bottom: var(--main-offset);
    right: var(--main-offset);
}

.toast {
    padding: 16px 24px;
    color: white;
    background: var(--color-primary);
    margin-bottom: 8px;
    transition: all .2s ease-in-out;
}

.toast__title {
    margin: 0 0 10px;
    font-size: 20px;
}

.toast_success {
    background: var(--color-success);
}

.toast_error {
    background: var(--color-error);
}
