.completed-fundraising {
    padding-top: 20px;
    position: relative;
}

.completed-fundraising .slick-slider_no-arrows {
    padding-bottom: calc(var(--slider-btn-height) + 40px);
}

.completed-fundraising__title {
    background: url('../../assets/images/wave-border.svg') no-repeat 0 calc(100% - 10px);
    display: flex;
    align-items: center;
    font-size: 128px;
    line-height: 170px;
    margin: 0 0 40px 0;
}

.completed-fundraising__title_text {
    margin-inline-end: 45px;
    width: min-content;
    white-space: nowrap;
}

.completed-fundraising__title_icon {
    width: 100%;
    background: var(--color-white);
    border-bottom-left-radius: 100px;
}

.completed-fundraising__show-all-link {
    color: var(--color-black);
    display: flex;
    font-size: 24px;
    position: absolute;
    bottom: 21px;
    left: 0;
    text-decoration: none;
    align-items: center;
}

.completed-fundraising__show-all-link:hover {
    text-decoration: underline;
}

.completed-fundraising__show-all-link:after {
    background: url('../../assets/images/arrow.svg') no-repeat;
    content: '';
    display: block;
    height: 26px;
    width: 26px;
    margin-inline-start: 20px;
    transform: rotate(180deg);
}

@media (max-width: 1200px) {
    .completed-fundraising__title {
        font-size: 88px;
        line-height: 120px;
    }
}

@media (max-width: 950px) {
    .completed-fundraising {
        padding-top: 40px;
    }

    .completed-fundraising__title {
        flex-direction: row-reverse;
        justify-content: flex-end;
        background: none;
        font-size: 32px;
        line-height: 42px;
        margin: 0 0 32px 0;
    }

    .completed-fundraising__title_icon {
        width: auto;
        margin-inline-end: 32px;
    }

    .completed-fundraising__show-all-link {
        position: initial;
        padding-top: 25px;
    }
}
