@font-face {
    font-family: 'e-Ukraine-Light';
    src: url('../fonts/e-Ukraine/e-Ukraine-Light.woff2') format('woff2'),
    url('../fonts/e-Ukraine/e-Ukraine-Light.woff') format('woff');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'e-Ukraine-Regular';
    src: url('../fonts/e-Ukraine/e-Ukraine-Regular.woff2') format('woff2'),
    url('../fonts/e-Ukraine/e-Ukraine-Regular.woff') format('woff');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'e-Ukraine-Bold';
    src: url('../fonts/e-Ukraine/e-Ukraine-Bold.woff2') format('woff2'),
    url('../fonts/e-Ukraine/e-Ukraine-Bold.woff') format('woff');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}
