.reports-page__main-title {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--color-white);
    height: 360px;
    padding-inline-start: 60px;
    display: flex;
    align-items: center;
    font-size: 44px;
    margin: 0;
}

.reports-page__main-title span {
    max-width: 500px;
    padding: 60px 0;
}

@media (max-width: 950px) {
    .reports-page__main-title {
        margin: 0 calc(var(--main-offset) * -1);
        font-size: 24px;
        padding-inline-start: 45px;
        align-items: flex-end;
    }

    .reports-page__main-title span {
        max-width: 285px;
        padding: 24px 0;
    }
}