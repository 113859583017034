.gratitudes__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, var(--fundraising-item-width));
    column-gap: 78px;
    row-gap: 60px;
    padding-top: 90px;
    justify-content: center;
}

.gratitude-item {
    border: 1px solid var(--color-black);
    margin-inline-end: 45px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 2px);
    width: var(--fundraising-item-width);
}

.gratitude-item__image {
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    background-color: #B6BBC187;
    height: 223px;
    min-height: 223px;
    width: 100%;
}

.gratitude-item__content {
    display: flex;
    flex-direction: column;
    justify-items: stretch;
    padding: 40px 24px 20px;
    height: 100%;
    max-height: 370px;
}

.gratitude-item__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
}

.gratitude-item__chevron {
    height: 45px;
    width: 45px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
}

.gratitude-item__content-title {
    margin: 0;
    font-size: 22px;
    line-height: 30px;
    padding-bottom: 16px;
    hyphens: auto;
}

.gratitude-item__content-description {
    margin: 0;
    overflow: auto;
    padding-bottom: 16px;
    font-size: 14px;
}