.translate-btn {
    border: 1px solid var(--color-black);
    background-color: var(--color-white);
    cursor: pointer;
    border-radius: 35px;
    margin-inline-end: 16px;
    height: 100%;
    width: 64px;
    transition: all .2s ease-in-out;
}

.translate-btn:hover {
    background-color: var(--color-primary-hover);
    border-color: var(--color-primary-hover);
    color: var(--color-white);
}

.translate-btn:active {
    background-color: var(--color-primary-active);
    border-color: var(--color-primary-active);
    color: var(--color-black);
}

@media (max-width: 950px) {
    .translate-btn {
        height: 58px;
        width: 58px;
    }
}
