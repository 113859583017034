.page {
    padding: 0 var(--main-offset) var(--main-offset);
    background-color: var(--background-color);
    min-width: calc(360px - calc(var(--main-offset) * 2));
}

.content {
    background-color: #ececec;
    height: 1000px;
    padding-top: 50px;
    text-align: center;
}

.page__fader {
    /* Can I use "inset" here? */
    position: fixed;
    bottom: 0;
    left: 0;
    height: 0;
    width: 100%;
    background-color: var(--color-black);
    opacity: 0;
    transition: all .15s ease-in;
}

.page__fader_visible {
    opacity: .3;
    height: 100%;
}
