.report {
    border: 1px solid var(--border-color);
    background-color: var(--color-white);
    transition: all .2s ease-in-out;
}

.report_opened {
    border: 1px solid var(--color-white);
    box-shadow: 0 14px 34px 0 rgba(67, 122, 187, 0.20);
}

.report-title {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: normal;
    margin: 0;
    padding: 22px 24px;
    cursor: pointer;
}

.report-title:before {
    content: '';
    display: flex;
    align-items: center;
    background: var(--color-white) url('../../../assets/images/check-icon.svg') no-repeat 50%;
    background-size: 50%;
    border: 1px solid var(--color-primary);
    border-radius: 50px;
    height: 50px;
    min-height: 50px;
    width: 50px;
    min-width: 50px;
}

.report-title:after {
    content: '';
    background: url('../../../assets/images/expand.svg') no-repeat 50%;
    height: 15px;
    width: 26px;
    margin-left: auto;
    transition: all .2s ease-in-out;
}

.report_opened .report-title:after {
    transform: rotateX(180deg);
}

.report-title__text {
    padding: 0 32px;
    transition: all .2s ease-in-out;
}

.report-title:hover .report-title__text {
    color: var(--color-primary-hover);
}

.report-items {
    max-height: 0;
    overflow: hidden;
    height: auto;
    transition: all .2s ease-in-out;
}

.report_opened .report-items {
    padding: 20px 40px 45px;
}
