.donation-widget {
  --widget-background: #f9f9f9;
  --widget-border: #e0e0e0;
  --widget-color: var(--color-black);
  --widget-box-shadow: rgba(0,0,0,0.1);

  --widget-option-background: var(--color-white);
  --widget-option-background-hover: #eaeaea;
  --widget-option-background-active: var(--color-black);
  --widget-option-border: #ccc;

  --widget-input-background: var(--color-white);
  --widget-input-border: #e0e0e0;
  --widget-input-border-focus: var(--color-black);

  --widget-symbol-color: #6d6d6d;

  --widget-donate-button-background: #e53e3e;
  --widget-donate-button-background-hover: #c53030;
  --widget-donate-button-color: var(--color-white);
  --widget-donate-button-color-hover: #ffecec;
  --widget-donate-button-box-shadow: rgba(229,62,62,0.5);
}

.donation-widget.dark {
  --widget-background: #181818;
  --widget-border: #363636;
  --widget-color: var(--color-white);
  --widget-box-shadow: rgba(0,0,0,0.3);

  --widget-option-border: #646464;
  --widget-option-background: #313131;
  --widget-option-background-hover: #4d4d4d;
  --widget-option-background-active: var(--color-white);

  --widget-input-background: #565656;
  --widget-input-border: #8b8b8b;
  --widget-input-border-focus: var(--color-white);

  --widget-symbol-color: #a9a9a9;
}


.donation-widget {
  background-color: var(--widget-background);
  padding: 20px 15px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 2px 4px var(--widget-box-shadow);
  border: 1px solid var(--widget-border);
  color: var(--widget-color);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.donation-options {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
  margin-bottom: 25px;
}

.donate-option-button {
  flex-grow: 1;
  padding: 12px 15px;
  font-size: 16px;
  border: 1px solid var(--widget-option-border);
  background-color: var(--widget-option-background);
  color: var(--widget-color);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  border-radius: 4px;
  text-align: center;
}

.donate-option-button:hover {
  background-color: var(--widget-option-background-hover);
  transform: translateY(-2px);
}

.donate-option-button:active {
  border: 1px solid var(--widget-option-background-active);
  outline: none;
}

.donate-option-button:focus {
  background-color: var(--widget-option-background-hover);
}

@media (max-width: 360px) {
  .donation-options {
    flex-wrap: wrap;
  }

  .donate-option-button {
    flex: 1 0 48%;
  }
}

.custom-amount-container {
  position: relative;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-amount {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  color: var(--widget-color);
  background-color: var(--widget-input-background);
  border: none;
  border-bottom: 2px solid var(--widget-input-border);
  padding: 12px;
  margin-bottom: 25px;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
}

.custom-amount::placeholder {
  color: var(--widget-color);
}

.custom-amount:focus {
  outline: none;
  border-bottom: 2px solid var(--widget-input-border-focus);
}

.input-label {
  margin-right: auto;
  margin-top: 0;
}

.symbol {
  position: absolute;
  right: 12px;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 25px;
  color: var(--widget-symbol-color);
}

.donate-button {
  background-color: var(--widget-donate-button-background);
  color: var(--widget-donate-button-color);
  padding: 12px 20px;
  border: none;
  font-size: 20px;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  letter-spacing: 0.05em;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  height: 56px;
  transition: background-color 0.3s, color 0.3s;
}

.donate-button:hover {
  background-color: var(--widget-donate-button-background-hover);
  color: var(--widget-donate-button-color-hover);
}

.donate-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--widget-donate-button-box-shadow);
}

@media (max-width: 600px) {
  .donation-options {
    gap: 8px;
  }
}
