.account-details-section {
    padding-top: 55px;
    position: relative;
}

.account-details-section__anchor {
    position: absolute;
    top: var(--header-height);
}

.account-details-section__title {
    font-size: 128px;
    line-height: 128px;
    margin: 0 0 65px 0;
}

.account-details-section__info-block {
    display: grid;
    grid-template-columns: 45% 55%;
}

.account-details-section__info-item {
    border: 1px solid var(--color-black);
    border-bottom: none;
    font-size: 24px;
    padding: 40px 45px;
}

.account-details-section__info-item:nth-child(2n) {
    border-left: none;
    border-bottom: none;
}

.account-details-section__info-item_privat {
    background-repeat: no-repeat;
    background-position: calc(100% - 38px) 50%;
    color: var(--color-black);
    text-decoration: none;
    padding-inline-end: 50%;
    position: relative;
    transition: all .2s ease-in-out;
}

.account-details-section__info-item_privat:hover {
    box-shadow: inset 0 0 0 1px var(--color-black);
}

.account-details-section__info-item_privat:after {
    content: '';
    position: absolute;
    right: -7px;
    bottom: -7px;
    background-color: var(--color-black);
    border-radius: 7px;
    height: 14px;
    width: 14px;
}

.account-details-section__info-item_mono {
    background-repeat: no-repeat;
    background-position: calc(100% - 38px) 50%;
    padding-inline-end: 30%;
    color: var(--color-black);
    text-decoration: none;
    position: relative;
    transition: all .2s ease-in-out;
}

.account-details-section__info-item_mono:hover {
    box-shadow: inset 0 0 0 1px var(--color-black);
}

.account-details-section__info-item_swift {
    background-color: var(--color-blue-light);
    border: none;
    border-top: 1px solid var(--color-black);
}

.account-details-section__info-item_all {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-blue-grey);
    border: none;
    border-top: 1px solid var(--color-black);
    color: var(--color-black);
    text-decoration: none;
}

.account-details-section__info-item_all-link {
    display: flex;
    align-items: center;
}

.account-details-section__info-item_all-link:after {
    content: '';
    background: url('../../assets/images/arrow.svg') no-repeat;
    min-width: 26px;
    width: 26px;
    height: 26px;
    transition: all .2s ease-in-out;
    transform: rotateY(180deg);
    margin-inline-start: 50px;
}

.account-details-section__info-item_all:hover {
    text-decoration: underline;
}

.account-details-section__info-item-value {
    display: flex;
    align-items: center;
    font-size: 16px;
    padding-top: 25px;
}

.account-details-section__info-item-label {
    font-family: var(--font-family-primary-bold);
    margin-inline-end: 16px;
}

.account-details-section__info-item-data {
    word-break: break-all;
    padding-inline-end: 16px;
}

.account-details-section__info-item-copy {
    border: 1px solid var(--color-black);
    border-radius: 25px;
    cursor: pointer;
    margin-inline-start: 16px;
    position: relative;
    height: 44px;
    width: 44px;
    min-width: 44px;
    transition: all .2s ease-in-out;
}

.account-details-section__info-item-copy:hover {
    background-color: var(--color-primary-hover);
    border-color: var(--color-primary-hover);
}

.account-details-section__info-item-copy:before,
.account-details-section__info-item-copy:after {
    background-color: var(--color-white);
    position: absolute;
    content: '';
    display: block;
    border: 2px solid var(--color-black);
    border-radius: 4px;
    height: 12px;
    width: 12px;
}

.account-details-section__info-item-copy:hover:before,
.account-details-section__info-item-copy:hover:after {
    background-color: var(--color-primary-hover) !important;
    border-color: var(--color-white);
}

.account-details-section__info-item-copy:before {
    top: 12px;
    left: 12px;
}

.account-details-section__info-item-copy:after {
    top: 16px;
    left: 16px;
}

.account-details-section__info-item_swift .account-details-section__info-item-copy:before,
.account-details-section__info-item_swift .account-details-section__info-item-copy:after {
    background-color: var(--color-blue-light);
}

@media (max-width: 950px) {
    .account-details-section {
        padding-top: 40px;
    }

    .account-details-section__title {
        font-size: 32px;
        line-height: 32px;
        margin-bottom: 32px;
    }

    .account-details-section__info-block {
        grid-template-columns: 1fr;
    }

    .account-details-section__info-item {
        padding: 32px 28px;
    }

    .account-details-section__info-item:nth-child(2n) {
        border-left: 1px solid var(--color-black);
    }

    .account-details-section__info-item_privat {
        background-position: calc(100% - 26px) 50%;
        padding-inline-end: 120px;
    }

    .account-details-section__info-item_privat:after {
        display: none;
    }

    .account-details-section__info-item_mono {
        background: none !important;
    }

    .account-details-section__info-item-copy {
        margin-inline-start: auto;
    }

    .account-details-section__info-item_swift .account-details-section__info-item-value {
        padding: 12px 0;
    }

    .account-details-section__info-item_swift .account-details-section__info-item-value:not(:last-child) {
        border-bottom: 1px solid var(--color-black);
    }

    .account-details-section__info-item_all {
        border: none !important;
        justify-content: flex-start;
    }
}
