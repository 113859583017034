.project-item {
    width: 296px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 2px);
}

.project-item__order {
    border: 1px solid var(--color-black);
    border-radius: 32px;
    line-height: 16px;
    padding: 14px 32px;
}

.project-item__order {
    display: inline-block;
}

.project-item__title {
    font-size: 16px;
}
