.social-link {
    display: block;
    height: 24px;
    width: 24px;
    text-indent: -99999px;
}

.social-links_facebook {
    background: url('../../assets/images/facebook-icon.svg') no-repeat 50%;
}

.social-links_linkedin {
    background: url('../../assets/images/linkedin-icon.svg') no-repeat 50%;
}

.social-links_instagram {
    background: url('../../assets/images/instagram-icon.svg') no-repeat 50%;
}

.social-links_linktree {
    background: url('../../assets/images/linktree-icon.svg') no-repeat 50%;
}

.social-links_tiktok {
    background: url('../../assets/images/tiktok-icon.svg') no-repeat 50%;
}