dialog[open] {
    opacity: 1;
    transform: scale(1);
}

dialog {
    padding: 0;
    font-size: 0;
    opacity: 0;
    transform: scale(0);
    transition: all 0.4s allow-discrete;
    max-height: 90vh;
    max-width: 90vw;
}

@starting-style {
    dialog[open] {
        opacity: 0;
        transform: scale(0);
    }
}

dialog::backdrop {
    background-color: rgb(0 0 0 / 0%);
    transition: all 0.4s allow-discrete;
}

dialog[open]::backdrop {
    background-color: rgb(0 0 0 / 25%);
}

@starting-style {
    dialog[open]::backdrop {
        background-color: rgb(0 0 0 / 0%);
    }
}

.dialog__image {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    max-height: 90vh;
    max-width: 90vw;
}

.dialog__close {
    border: none;
    background: var(--color-white);
    cursor: pointer;
    display: block;
    height: 30px;
    width: 30px;
    padding: 0;
    position: absolute;
    inset-inline-end: 0;
    inset-block-start: 0;

    &:before {
        content: '';
        display: block;
        border-top: 2px solid var(--color-black);
        margin-block-end: -2px;
        width: 30px;
        transition: all .2s ease-in-out;
        transform: rotate(-45deg);
    }

    &:after {
        content: '';
        display: block;
        border-top: 2px solid var(--color-black);
        transform: rotate(45deg);
        width: 30px;
        transition: all .2s ease-in-out;
    }
}